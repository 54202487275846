import { api } from '../index'
import { Association } from './associations'
import { Competition } from './competitions'
import { Match } from './matches'
import { Participant } from './participants'
import { Team } from './teams'

export interface Incident {
  _id: string
  association: Association
  competition: Competition
  match: Match
  type: string
  team: Team
  participant: Participant
  incident: {
    allegedIncident: string
    directedTowards: string
    actionTaken: string
  }
  injury: {
    injuryType: string
    injuryArea: string
    actionTaken: string
  }
  comment: string
  file: string
  outcome: {
    assessment: string
    result: string
    suspensionEnds: string
    comments: string
    actionedBy: string
  }
}

export interface QueryParams {
  offset: number
  limit: number
  search: string
}

type IncidentsResponse = Incident[]

export const incidentsApi = api.injectEndpoints({
  endpoints: build => ({
    getIncidents: build.query<IncidentsResponse, QueryParams>({
      query: params => {
        const { offset, limit, search } = params
        return {
          url: 'incidents/',
          params: {
            offset,
            limit,
            search,
          },
        }
      },
      providesTags: ['incidents'],
    }),
    getIncident: build.query<Incident, string>({
      query: id => `incidents/${id}`,
      providesTags: ['incident'],
    }),
    addIncident: build.mutation({
      query: body => ({
        url: 'incidents',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['incidents'],
    }),
    updateIncident: build.mutation({
      query: body => ({
        url: `incidents/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['incidents', 'incident'],
    }),
    deleteIncident: build.mutation({
      query: id => ({
        url: `incidents/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['incident', 'incidents'],
    }),
  }),
})

export const {
  useGetIncidentQuery,
  useGetIncidentsQuery,
  useLazyGetIncidentsQuery,
  useAddIncidentMutation,
  useUpdateIncidentMutation,
  useDeleteIncidentMutation,
} = incidentsApi
