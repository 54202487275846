import { api } from '..'
import { Competition } from './competitions'
import { Participant } from './participants'
import { Team } from './teams'
import { Venue } from './venues'

export enum MatchDisplayName {
  ELIMINATION_FINAL = 'Elimination Final',
  QUALIFYING_FINAL = 'Qualifying Final',
  SEMI_FINAL = 'Semi Final',
  PRELIMINARY_FINAL = 'Preliminary Final',
  GRAND_FINAL = 'Grand Final',
}

export enum RoundCategory {
  REGULAR_SEASON = 'Regular season',
  FINALS = 'Finals',
}

export enum Status {
  FIXTURE = 1,
  POSTPONED = 2,
  ABANDONED = 3,
  FORFEITED = 4,
  FINAL = 5,
}

export interface Round {
  category: RoundCategory
  number: string
}

export interface Match {
  _id: string
  ttr_id: number
  competition: Competition
  round: {
    category: string
    number: string
  }
  matchDisplayName: MatchDisplayName | ''
  venue: Venue
  fieldNo: string
  time: string
  homeTeam: Team | null
  awayTeam: Team | null
  referee: Participant
  score: {
    homeTeam: number
    awayTeam: number
  }
  offset: {
    homeTeam: number
    awayTeam: number
  }
  tryScorers: any
  status: number
  bye: boolean
  tbc: boolean
}

export interface QueryParams {
  offset: number
  limit: number
  search: string
  competition: string
}

type MatchesResponse = Match[]

export const matchesApi = api.injectEndpoints({
  endpoints: build => ({
    getMatches: build.query<MatchesResponse, QueryParams>({
      query: params => {
        const { offset, limit, search, competition } = params
        return {
          url: 'matches/',
          params: {
            offset,
            limit,
            search,
            competition,
          },
        }
      },
      providesTags: ['matches'],
    }),
    getMatch: build.query<Match, string>({
      query: id => `matches/${id}`,
      providesTags: ['match'],
    }),
    getMatchesByCompetition: build.query<MatchesResponse, string>({
      query: competitionId => `matches/competition/${competitionId}`,
    }),
    addMatch: build.mutation({
      query: body => ({
        url: 'matches',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['matches'],
    }),
    updateMatch: build.mutation({
      query: body => {
        return {
          url: `matches/${body.id}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['matches', 'match'],
    }),
    assignReferee: build.mutation({
      query: body => {
        return {
          url: `matches/referee`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['matches', 'match'],
    }),
    deleteMatch: build.mutation({
      query: id => ({
        url: `matches/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['matches'],
    }),
  }),
})

export const {
  useLazyGetMatchesByCompetitionQuery,
  useGetMatchesQuery,
  useGetMatchQuery,
  useAddMatchMutation,
  useUpdateMatchMutation,
  useAssignRefereeMutation,
  useDeleteMatchMutation,
  useLazyGetMatchesQuery,
} = matchesApi
