import { api } from '../index'
import { Association } from './associations'
import { Division } from './divisions'
import { Team } from './teams'
import { Venue } from './venues'

export interface Competition {
  _id: string
  ttr_id: number
  name: string
  association: Association
  division: Division
  grade: string
  dayPlayed: string
  venue: Venue
  numberOfRounds: number
  finalsSystem: string
  startDate: string
  time: string
  repTournament: number
  registrationSettings: {
    team: {
      price: number
      startDate: string
      endDate: string
      open: number
    }
    player: {
      price: number
      startDate: string
      endDate: string
      open: number
    }
    staff: {
      price: number
      startDate: string
      endDate: string
      open: number
    }
  }
  teams: Team[]
  display: {
    matches: number
    results: number
    ladders: number
  }
}

export interface QueryParams {
  offset: number
  limit: number
  search: string
  currentSeason: boolean
  association?: boolean
}

export type CompetitionsResponse = Competition[]

export const competitionsApi = api.injectEndpoints({
  endpoints: build => ({
    getCompetitions: build.query<CompetitionsResponse, QueryParams>({
      query: params => {
        const { offset, limit, search, currentSeason = false } = params
        return {
          url: 'competitions/',
          params: {
            offset,
            limit,
            search,
            currentSeason,
          },
        }
      },
      providesTags: ['competitions'],
    }),
    getCommunicationCompetitions: build.query<
      CompetitionsResponse,
      QueryParams
    >({
      query: params => {
        const { offset, limit, search, currentSeason = false } = params
        return {
          url: 'communication/competitions',
          params: {
            offset,
            limit,
            search,
            currentSeason,
          },
        }
      },
      providesTags: ['competitions'],
    }),
    getCompetition: build.query<Competition, string>({
      query: id => `competitions/${id}`,
      providesTags: ['competition'],
    }),
    addCompetition: build.mutation({
      query: body => ({
        url: 'competitions',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['competitions'],
    }),
    updateCompetition: build.mutation({
      query: body => ({
        url: `competitions/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['competitions', 'competition'],
    }),
    deleteCompetition: build.mutation({
      query: id => ({
        url: `competitions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['competition', 'competitions'],
    }),
  }),
})

export const {
  useGetCompetitionsQuery,
  useGetCompetitionQuery,
  useLazyGetCompetitionsQuery,
  useLazyGetCommunicationCompetitionsQuery,
  useAddCompetitionMutation,
  useUpdateCompetitionMutation,
  useDeleteCompetitionMutation,
} = competitionsApi
