import { api } from '..'
import { Association } from './associations'
import { Participant } from './participants'
import { Season } from './seasons'

export interface Team {
  _id: string
  ttr_id: number
  name: string
  association: Association
  season: Season
  contactName: string
  contactEmail: string
  contactNumber: string
}

export interface QueryParams {
  offset: number
  limit: number
  search: string
  currentSeason: boolean
}

type TeamsResponse = Team[]

export const teamsApi = api.injectEndpoints({
  endpoints: build => ({
    getTeams: build.query<TeamsResponse, QueryParams>({
      query: params => {
        const { offset, limit, search, currentSeason = false } = params
        return {
          url: 'teams/',
          params: {
            offset,
            limit,
            search,
            currentSeason,
          },
        }
      },
      providesTags: ['teams'],
    }),
    getTeamPlayers: build.query<Participant[], string>({
      query: id => `team/players/${id}`,
      providesTags: ['team', 'teams'],
    }),
    getReferees: build.query<Participant[], Partial<QueryParams>>({
      query: params => {
        const currentSeason = params?.currentSeason ?? false
        return {
          url: `referees`,
          params: {
            currentSeason,
          },
        }
      },
    }),
    getTeam: build.query<Team, string>({
      query: id => `teams/${id}`,
      providesTags: ['team'],
    }),
    addTeam: build.mutation({
      query: body => ({
        url: 'teams',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['teams'],
    }),
    updateTeam: build.mutation({
      query: body => ({
        url: `teams/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['teams', 'team'],
    }),
    deleteTeam: build.mutation({
      query: id => ({
        url: `teams/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['team', 'teams'],
    }),
    transferParticipantToTeam: build.mutation({
      query: ({ toTeamId, ...body }) => ({
        url: `/team/players/transfer/${toTeamId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['team', 'teams'],
    }),
  }),
})

export const {
  useGetRefereesQuery,
  useGetTeamsQuery,
  useGetTeamQuery,
  useLazyGetTeamsQuery,
  useGetTeamPlayersQuery,
  useLazyGetTeamPlayersQuery,
  useAddTeamMutation,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  useTransferParticipantToTeamMutation,
} = teamsApi
