import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PageProps } from 'gatsby'
import Layout from 'manage-tritag/components/layout'
import IncidentEditForm from 'manage-tritag/components/pages/incidents/edit-form'

const AssociationEditPage = (props: PageProps) => {
  return (
    <Layout label1="Associations" label2="Update" url="/associations">
      <IncidentEditForm {...props} />
    </Layout>
  )
}

export default withAuthenticationRequired(AssociationEditPage)
